import { getIsEcoBonusEligible } from "@lib/getIsEcoBonusEligible";
import { useConfigurationSelector } from "@redux/reducers/appConfig";

import {
  useBusinessModelSelector,
  useSelectedJourneySelector,
  useFinanceDataDurationSelector,
} from "@redux/reducers/deal";

export const useIsEcoBonusEligible = () => {
  const configuration = useConfigurationSelector();
  const duration = useFinanceDataDurationSelector();
  const journey = useSelectedJourneySelector();
  const businessModel = useBusinessModelSelector();

  return getIsEcoBonusEligible(configuration, duration, journey, businessModel);
};
