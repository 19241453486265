import { produce } from "immer";

export const transformConfigurationToDealLikeObject = configuration => {
  return produce(configuration, dealLikeObject => {
    dealLikeObject.options = configuration.options.concat(
      configuration.accessory
    );

    dealLikeObject.services = configuration.service;

    delete dealLikeObject.service;
    delete dealLikeObject.accessory;
  });
};
