import { usePrevious } from "react-use";
import shasum from "shasum";

export const useDidObjectChange = object => {
  // shasum does not accept undefined
  const objectHash = object === undefined ? "undefined" : shasum(object);
  const previousObjectHash = usePrevious(objectHash);

  return objectHash !== previousObjectHash;
};
