import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "@lib/i18n";
import { usePrevious } from "react-use";

import useBasketState from "@hooks/useBasketState";
import { validateCar } from "@shared/validator/carValidator";
import DeliveryMethodValidator from "@shared/validator/DeliveryMethodValidator";

import { useRandomKey } from "@hooks/useRandomKey";
import { useFormattedDeal } from "@hooks/useFormattedDeal";
import { useDidObjectChange } from "@hooks/useDidObjectChange";
import { useEffect } from "react";
import { transformConfigurationToDealLikeObject } from "@lib/transformConfigurationToDealLikeObject";
import { CTA_SOL_TYPES } from "@shared/constants";
import { REQUEST_ERROR_CODES } from "@shared/constants";
import { useDeliveryMethods } from "@hooks/useDeliveryMethods";
import { setBasketErrorCodesAction } from "@redux/actions/errorsActions";
import { useBasketErrorCodesSelector } from "@redux/reducers/errors";
import { useLastCtaSelector } from "@redux/reducers/deal";
import { getIsB2BSelected } from "@shared/v2/lib/getIsB2BSelected";
import { useUnavailableItemsSelector } from "@redux/reducers/errors";
import { setUnavailableItemsAction } from "@redux/actions/errorsActions";

import {
  updatePreconfigurationLeadTimes,
  calculatePricesAction,
  forceUpdateCarConfigurationDataAction,
} from "@redux/actions/dealActions";

import {
  useCarSelector,
  usePreconfigurationsSelector,
} from "@redux/reducers/appConfig";

export const useCarValidation = deal => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const basketErrorCodes = useBasketErrorCodesSelector();
  const unavailableItems = useUnavailableItemsSelector();
  const previousBasketErrorCodes = usePrevious(basketErrorCodes);
  const haveBasketErrorCodesChanged = useDidObjectChange(basketErrorCodes);
  const formatDeal = useFormattedDeal();
  const lastCta = useLastCtaSelector();
  const availableCar = useCarSelector();
  const preconfigurations = usePreconfigurationsSelector();
  const isB2BSelected = getIsB2BSelected(deal.businessModel);

  const formattedDeal = formatDeal(deal);

  const {
    carConfiguration: selectedConfiguration,
    userProfile: { deliveryData: dealDeliveryMethod },
    businessModel,
  } = formattedDeal;

  const selectedPreconfigurationId = selectedConfiguration.preconfiguration.id;
  const deliveryMethodValidator = new DeliveryMethodValidator();
  const availableDeliveryMethods = useDeliveryMethods(businessModel);

  const [
    deliveryStateMachineKey,
    regenerateDeliveryStateMachineKey,
  ] = useRandomKey();

  const [isCarValidated, setIsCarValidated] = useState(false);

  const isInvalidPreconfiguration =
    basketErrorCodes.includes(
      REQUEST_ERROR_CODES.PRECONFIGURATION_UNAVAILABLE
    ) || basketErrorCodes.includes(REQUEST_ERROR_CODES.OPTIONS_UNAVAILABLE);

  const isDeliveryMethodUnavailable = basketErrorCodes.includes(
    REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE
  );

  const isDeliveryPointUnavailable = basketErrorCodes.includes(
    REQUEST_ERROR_CODES.DELIVERY_POINT_UNAVAILABLE
  );

  const {
    openSummary,
    openDelivery,
    openPersonalInfoAndDelivery,
  } = useBasketState(i18n.language, formattedDeal);

  useEffect(() => {
    if (!selectedPreconfigurationId) {
      return;
    }

    const carValidationResult = validateCar(
      availableCar,
      transformConfigurationToDealLikeObject(selectedConfiguration),
      isB2BSelected
    );

    const deliveryMethodValidationResult = deliveryMethodValidator.validate(
      availableDeliveryMethods,
      dealDeliveryMethod
    );

    const validationResult = {
      ...deliveryMethodValidationResult,
      ...carValidationResult,
    };

    const errorCodesMap = {
      isPreconfigurationAvailable:
        REQUEST_ERROR_CODES.PRECONFIGURATION_UNAVAILABLE,
      areOptionsAvailable: REQUEST_ERROR_CODES.OPTIONS_UNAVAILABLE,
      isSameCashPrice: REQUEST_ERROR_CODES.CASH_PRICE_CHANGED,
      isSameLeadTime: REQUEST_ERROR_CODES.LEAD_TIME_CHANGED,
      isDeliveryMethodFound: REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE,
      isDeliveryMethodEnabled: REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE,
      isDeliveryPointAvailable: REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE,
    };

    const errors = Object.entries(validationResult)
      .filter(([, value]) => value === false)
      .map(([key]) => errorCodesMap[key]);

    dispatch(setBasketErrorCodesAction(errors));
    dispatch(setUnavailableItemsAction(carValidationResult.unavailableItems));
    setIsCarValidated(true);
  }, [selectedPreconfigurationId]);

  useEffect(() => {
    if (!haveBasketErrorCodesChanged) {
      return;
    }

    if (isInvalidPreconfiguration) {
      openSummary();

      return;
    }

    if (isDeliveryMethodUnavailable) {
      openDelivery();
      // This forces re-initialization of the Delivery state machine, applying the initial state from props
      // This is a temporary workaround for initial state of Delivery being different after useEffects run
      // and also a workaround for the Delivery state machine code which does not allow control from top down,
      // which will change when it gets rewritten to X-State later on
      regenerateDeliveryStateMachineKey();
    }

    if (basketErrorCodes.includes(REQUEST_ERROR_CODES.CASH_PRICE_CHANGED)) {
      // In case that prices have changed, we will use the current base prices,
      // and update finance data
      dispatch(forceUpdateCarConfigurationDataAction());
      dispatch(calculatePricesAction());
    }

    if (basketErrorCodes.includes(REQUEST_ERROR_CODES.LEAD_TIME_CHANGED)) {
      const latestPreconfiguration = preconfigurations.find(
        ({ id }) => id === selectedPreconfigurationId
      );

      dispatch(
        updatePreconfigurationLeadTimes(latestPreconfiguration.leadTimes)
      );

      const wasDeliveryMethodUnavailable =
        !isDeliveryMethodUnavailable &&
        previousBasketErrorCodes.includes(
          REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE
        );
      // If user is on the MON PANIER or MA LIVRASION step, we should not take any action
      // If we have DELIVERY_METHOD_UNAVAILABLE, we dont want to open the personal info section
      // If we just solved the DELIVERY_METHOD_UNAVAILABLE error by selecting a new delivery method,
      // we dont want to open personal info just yet (user still needs to confirm the new delivery method first)
      if (
        [
          CTA_SOL_TYPES.VEHICLE_SELECTED,
          CTA_SOL_TYPES.VEHICLE_CONFIRMED,
        ].includes(lastCta) ||
        wasDeliveryMethodUnavailable ||
        isDeliveryMethodUnavailable
      ) {
        return;
      }

      openPersonalInfoAndDelivery();
    }
  }, [
    basketErrorCodes,
    haveBasketErrorCodesChanged,
    previousBasketErrorCodes,
    lastCta,
    isDeliveryMethodUnavailable,
    isDeliveryPointUnavailable,
  ]);

  return {
    isDeliveryMethodUnavailable,
    isDeliveryPointUnavailable,
    isInvalidPreconfiguration,
    deliveryStateMachineKey,
    isCarValidated,
    unavailableItems,
  };
};
