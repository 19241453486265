import { useReducer, useCallback, useState } from "react";
import produce from "immer";

import { CTA_SOL_TYPES, DELIVERY_METHOD } from "@shared/constants";
import { useLastCtaSelector } from "@redux/reducers/deal";

const BASKET_ACTION = {
  CLOSE_ALL: "CLOSE_ALL",
  SUMMARY: "summary",
  TOGGLE_SUMMARY: "TOGGLE_SUMMARY",
  OPEN_DELIVERY: "OPEN_DELIVERY",
  CLOSE_DELIVERY: "CLOSE_DELIVERY",
  OPEN_PERSONAL_INFO: "OPEN_PERSONAL_INFO",
  CLOSE_PERSONAL_INFO: "CLOSE_PERSONAL_INFO",
  PERSONAL_INFO_AND_DELIVERY: "personal_info_and_delivery",
  OPEN_VEHICLE_REGISTRATION: "OPEN_VEHICLE_REGISTRATION",
  CLOSE_VEHICLE_REGISTRATION: "CLOSE_VEHICLE_REGISTRATION",
};

const initialBasketState = {
  isSummaryOpen: false,
  isDeliveryOpen: false,
  isPersonalInformationOpen: false,
  isVehicleRegistrationOpen: false,
};

const basketStateReducer = (state, { type }) => {
  switch (type) {
    case BASKET_ACTION.CLOSE_ALL:
      return initialBasketState;

    case BASKET_ACTION.TOGGLE_SUMMARY:
      return produce(state, draft => {
        draft.isSummaryOpen = !state.isSummaryOpen;
      });

    case BASKET_ACTION.SUMMARY:
      return produce(initialBasketState, draft => {
        draft.isSummaryOpen = true;
      });

    case BASKET_ACTION.OPEN_DELIVERY:
      return produce(state, draft => {
        draft.isDeliveryOpen = true;
      });

    case BASKET_ACTION.CLOSE_DELIVERY:
      return produce(state, draft => {
        draft.isDeliveryOpen = false;
      });

    case BASKET_ACTION.OPEN_PERSONAL_INFO:
      return produce(state, draft => {
        draft.isPersonalInformationOpen = true;
      });

    case BASKET_ACTION.CLOSE_PERSONAL_INFO:
      return produce(state, draft => {
        draft.isPersonalInformationOpen = false;
      });

    case BASKET_ACTION.PERSONAL_INFO_AND_DELIVERY:
      return produce(initialBasketState, draft => {
        draft.isPersonalInformationOpen = true;
        draft.isDeliveryOpen = true;
        // If vehicle registration was already open, keep it open so user can continue
        // Otherwise user would have no CTA on the personal information section and would be "stuck"
        // unless he would "edit" some fields to get the CTA button back
        draft.isVehicleRegistrationOpen = state.isVehicleRegistrationOpen;
      });

    case BASKET_ACTION.OPEN_VEHICLE_REGISTRATION:
      return produce(state, draft => {
        draft.isVehicleRegistrationOpen = true;
      });

    case BASKET_ACTION.CLOSE_VEHICLE_REGISTRATION:
      return produce(state, draft => {
        draft.isVehicleRegistrationOpen = false;
      });

    default:
      return state;
  }
};

const useBasketState = (language, formattedDeal) => {
  const {
    userProfile,
    deliveryDealer,
    userProfile: { deliveryData },
  } = formattedDeal;

  const deliveryType = deliveryData?.type;
  const lastCta = useLastCtaSelector();

  const getInitialBasketState = useCallback(
    () =>
      produce(initialBasketState, draft => {
        switch (lastCta) {
          case CTA_SOL_TYPES.VEHICLE_CONFIRMED:
            draft.isDeliveryOpen = true;
            return;

          case CTA_SOL_TYPES.DELIVERY_POINT_SELECTED:
            draft.isPersonalInformationOpen = true;
            return;

          case CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED:
            draft.isVehicleRegistrationOpen = true;
            return;

          default:
            draft.isSummaryOpen = true;
        }
      }),
    []
  );

  const [basketState, dispatch] = useReducer(
    basketStateReducer,
    getInitialBasketState()
  );

  const [isShareConfigModalOpen, setIsShareConfigModalOpen] = useState(false);

  const [isMyDetailsConfirmed, setIsMyDetailsConfirmed] = useState(
    CTA_SOL_TYPES.PERSONAL_DETAILS_CONFIRMED === lastCta
  );

  const [isBasketConfirming, setIsBasketConfirming] = useState(false);

  const [isHomeDelivery, setIsHomeDelivery] = useState(
    deliveryType === DELIVERY_METHOD.HOME
  );

  const [deliveryAddress, setDeliveryAddress] = useState(
    isHomeDelivery ? userProfile : deliveryDealer
  );

  // Basket state utility functions
  const closeAll = useCallback(() => {
    dispatch({ type: BASKET_ACTION.CLOSE_ALL });
  }, []);

  const openSummary = useCallback(() => {
    dispatch({ type: BASKET_ACTION.SUMMARY });
  }, []);

  const toggleSummary = () => dispatch({ type: BASKET_ACTION.TOGGLE_SUMMARY });

  const openDelivery = useCallback(() => {
    dispatch({ type: BASKET_ACTION.OPEN_DELIVERY });
  }, []);

  const closeDelivery = useCallback(() => {
    dispatch({ type: BASKET_ACTION.CLOSE_DELIVERY });
  }, []);

  const openPersonalInfo = useCallback(() => {
    dispatch({ type: BASKET_ACTION.OPEN_PERSONAL_INFO });
  }, []);

  const closePersonalInfo = useCallback(() => {
    dispatch({ type: BASKET_ACTION.CLOSE_PERSONAL_INFO });
  }, []);

  const openPersonalInfoAndDelivery = useCallback(() => {
    dispatch({ type: BASKET_ACTION.PERSONAL_INFO_AND_DELIVERY });
  }, []);

  const openVehicleRegistration = useCallback(() => {
    dispatch({ type: BASKET_ACTION.OPEN_VEHICLE_REGISTRATION });
  }, []);

  const closeVehicleRegistration = useCallback(() => {
    dispatch({ type: BASKET_ACTION.CLOSE_VEHICLE_REGISTRATION });
  }, []);

  // My Details utility functions
  const confirmDetails = useCallback(() => {
    setIsMyDetailsConfirmed(true);
  }, []);

  const unconfirmDetails = useCallback(() => {
    setIsMyDetailsConfirmed(false);
  }, []);

  // Basket confirming utility functions
  const startBasketConfirming = useCallback(() => {
    setIsBasketConfirming(true);
  }, []);

  const stopBasketConfirming = useCallback(() => {
    setIsBasketConfirming(false);
  }, []);

  return {
    basketState,
    closeAll,
    openSummary,
    toggleSummary,
    openDelivery,
    closeDelivery,
    openPersonalInfo,
    closePersonalInfo,
    openVehicleRegistration,
    closeVehicleRegistration,
    openPersonalInfoAndDelivery,

    isShareConfigModalOpen,
    setIsShareConfigModalOpen,

    isMyDetailsConfirmed,
    confirmDetails,
    unconfirmDetails,

    isHomeDelivery,
    setIsHomeDelivery,

    deliveryAddress,
    setDeliveryAddress,

    isBasketConfirming,
    startBasketConfirming,
    stopBasketConfirming,
  };
};

export default useBasketState;
