const { produce } = require("immer");

const LeadTimeHelper = require("../helper/LeadTimeHelper");
const getPriceAlgorithm = require("../PriceAlgorithm/getPriceAlgorithm");

const {
  CUSTOMIZE_TYPES,
  BUSINESS_MODELS_TARGET_KEYS,
} = require("../constants");

const {
  isConfigurationAvailable,
} = require("../helper/isConfigurationAvailable");

const leadTimeHelper = new LeadTimeHelper();

const getOptionIds = ({ options }) => options.map(({ id }) => id);
const getServiceIds = ({ services }) => services.map(({ id }) => id);

const getAreAllPersonalizationOptionsAvailable = (
  option,
  globalAvailableOptions
) => {
  const globalAvailableOptionsIds = globalAvailableOptions.map(
    globalAvailableOption => globalAvailableOption.id
  );

  const unAvailablePersonalizationOption = option.personalizationOptions.find(
    personalizationOption =>
      !globalAvailableOptionsIds.includes(personalizationOption.id)
  );

  if (unAvailablePersonalizationOption) {
    const debugVerbose = require("debug")("app:verbose:carValidator");

    debugVerbose(
      `Following personalization option is not available: ${JSON.stringify(
        unAvailablePersonalizationOption
      )}`
    );
  }

  return !unAvailablePersonalizationOption;
};

const getSelectedPackAndPersonalizationIds = ({ options }) =>
  options
    .filter(
      option =>
        option.type === CUSTOMIZE_TYPES.PACK ||
        option.type === CUSTOMIZE_TYPES.PERSONALIZATION
    )
    .map(({ id }) => id);

const getSelectedAccessoryIds = ({ options }) =>
  options
    .filter(option => option.type === CUSTOMIZE_TYPES.ACCESSORY)
    .map(({ id }) => id);

// TODO: Rewrite validateCar with appConfig and deal object
// This is necessary because of the change structure of the csv
const validateCar = (latestCarObject, selectedCarObject, isB2BSelected) => {
  const priceAlgorithm = getPriceAlgorithm(latestCarObject.country);

  const {
    options,
    preconfiguration: selectedPreconfiguration,
    services,
  } = selectedCarObject;

  const latestMatchingPreconfiguration = latestCarObject.preconfigurations.find(
    ({ id }) => id === selectedPreconfiguration.id
  );

  const globalAvailablePreconfigurations = latestCarObject.preconfigurations.filter(
    isConfigurationAvailable(isB2BSelected)
  );

  const globalAvailableOptions = latestCarObject.options.filter(
    option => option.status === true
  );

  const globalAvailableServices = services.filter(service => {
    const targetKey = isB2BSelected
      ? BUSINESS_MODELS_TARGET_KEYS.B2B
      : BUSINESS_MODELS_TARGET_KEYS.B2C;

    return service[targetKey] === true;
  });

  const isPreconfigurationGloballyAvailable = globalAvailablePreconfigurations.some(
    globalAvailablePreconfiguration =>
      globalAvailablePreconfiguration.id === selectedPreconfiguration.id
  );

  const unavailableOptions = options.filter(option => {
    const isOptionAvailable = globalAvailableOptions.some(
      globalOption => globalOption.id === option.id
    );

    if (!isOptionAvailable) {
      return true;
    }

    if (!option.personalizationOptions) {
      return false;
    }

    const areAllPersonalizationOptionsAvailable = getAreAllPersonalizationOptionsAvailable(
      option,
      globalAvailableOptions
    );

    return !areAllPersonalizationOptionsAvailable;
  });

  const unavailableServices = services.filter(
    service =>
      !globalAvailableServices.some(
        globalService => globalService.id === service.id
      )
  );

  const unavailableItems = produce(
    unavailableOptions.concat(unavailableServices),
    draft => {
      if (!isPreconfigurationGloballyAvailable) {
        draft.push(selectedPreconfiguration);
      }
    }
  );

  // Get all accessories, services and packs and personalizations of deal
  const selectedAccessoryIds = getSelectedAccessoryIds(selectedCarObject);
  const selectedPacksAndPersonalizationIds = getSelectedPackAndPersonalizationIds(
    selectedCarObject
  );

  // Check if all selected packs and personalizations (default for configuration and selected ones) are present
  // in list of available options for chosen preconfiguration
  const allSelectedPacksAndPersonalizationsExist = selectedPacksAndPersonalizationIds.every(
    selectedOptionId =>
      latestMatchingPreconfiguration &&
      latestMatchingPreconfiguration.options.includes(selectedOptionId)
  );

  const allSelectedAccessoriesExist = selectedAccessoryIds.every(
    selectedAccessoryId =>
      latestMatchingPreconfiguration &&
      latestMatchingPreconfiguration.options.includes(selectedAccessoryId)
  );

  const areAllItemsAvailable = unavailableItems.length === 0;
  // Preconfigurations is valid only when all options and services are
  // available globally and for chosen configuration
  const isPreconfigurationAvailable = Boolean(
    isPreconfigurationGloballyAvailable &&
      areAllItemsAvailable &&
      allSelectedAccessoriesExist &&
      allSelectedPacksAndPersonalizationsExist &&
      latestMatchingPreconfiguration
  );

  const latestLeadTimes = latestMatchingPreconfiguration
    ? leadTimeHelper.getLeadTimes(
        latestMatchingPreconfiguration,
        selectedAccessoryIds
      )
    : null;

  const selectedLeadTimes = leadTimeHelper.getLeadTimes(
    selectedPreconfiguration,
    selectedAccessoryIds
  );

  const isSameLeadTime =
    latestLeadTimes === null
      ? false
      : latestLeadTimes.homeDelivery === selectedLeadTimes.homeDelivery &&
        latestLeadTimes.pickupLocation === selectedLeadTimes.pickupLocation;

  const dealBase = {
    isScrappageSelected: false,
    userProfile: { deliveryDealer: {} },
    financeSimulation: {
      journey: "cash",
    },
    businessModel: "b2c",
  };

  const appConfiguration = {
    promoAmount: 0,
    app: {
      car: {
        netPrice: 7200,
      },
      solJourney: {
        taxPercentage: 22,
        ecoBonus: {
          governmentDiscountPercentage: 30,
        },
        cashJourney: {
          scrappage: {
            discountPercentage: 0,
          },
        },
        financeJourney: {
          scrappage: {
            discountPercentage: 0,
          },
        },
        loaJourney: {
          scrappage: {
            discountPercentage: 0,
          },
        },
      },
    },
  };

  const selectedTotalCashPrice = priceAlgorithm.getVehiclePrice(
    produce(dealBase, draft => {
      draft.carConfiguration = {
        preconfiguration: selectedCarObject.preconfiguration,

        options: selectedCarObject.options,
        service: selectedCarObject.services,
        accessory: [],
      };
    }),
    {
      configuration: appConfiguration,
    }
  );

  const selectedOptionsIds = getOptionIds(selectedCarObject);
  const selectedServicesIds = getServiceIds(selectedCarObject);

  const latestTotalCashPrice = priceAlgorithm.getVehiclePrice(
    produce(dealBase, draft => {
      draft.carConfiguration = {
        preconfiguration: latestCarObject.preconfigurations.find(
          preconfiguration =>
            preconfiguration.id === selectedCarObject.preconfiguration.id
        ),

        accessory: [],
        options: latestCarObject.options.filter(({ id }) =>
          selectedOptionsIds.includes(id)
        ),
        service: latestCarObject.services.filter(
          ({ id, lcdvCode }) =>
            selectedServicesIds.includes(id) &&
            selectedPreconfiguration.lcdvCode === lcdvCode
        ),
      };
    }),
    {
      configuration: appConfiguration,
    }
  );

  const isSameCashPrice =
    selectedTotalCashPrice.netPrice === latestTotalCashPrice.netPrice;

  return {
    areOptionsAvailable: areAllItemsAvailable,
    isPreconfigurationAvailable,
    isSameCashPrice,
    isSameLeadTime,
    unavailableItems,
  };
};

module.exports = { validateCar };
