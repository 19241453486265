import React from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { useTranslation } from "@lib/i18n";

import Typography from "@components/shared/Typography";
import Modal from "@components/shared/Modal";

import { fontSize, fontWeight, prop } from "@components/shared/utils";
import { usePrimaryColor } from "@hooks/usePrimaryColor";

const StyledModal = styled(Modal)`
  padding: 1rem 2rem;
`;

const StyledHeadingTypography = styled(Typography)`
  color: ${prop("color")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("lg")};
  text-align: center;
  text-transform: uppercase;
`;

const DiscountDetailsModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const primaryColor = usePrimaryColor();

  return (
    <StyledModal
      isOpen={isOpen}
      withCloseIcon
      ariaLabel={t("price.modalDiscountDetails.heading")}
      onClose={onClose}
    >
      <StyledHeadingTypography color={primaryColor}>
        {t("price.modalDiscountDetails.heading")}
      </StyledHeadingTypography>
      <Typography size="sm" margin="0.5rem 0">
        {t("price.modalDiscountDetails.mainInfo")}
      </Typography>
    </StyledModal>
  );
};

DiscountDetailsModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
};

export default DiscountDetailsModal;
