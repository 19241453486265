import { useRouter } from "next/router";

import { priceAlgorithm } from "@lib/priceAlgorithm";
import { JOURNEY_TYPE } from "@shared/constants";
import { useFormattedDeal } from "@hooks/useFormattedDeal";
import { getIsDeliveryPriceIncluded } from "@lib/getIsDeliveryPriceIncluded";
import { getIsRegistrationDiscountApplied } from "@shared/v2/lib/getIsRegistrationDiscountApplied";
import { useIsOfferSummaryPage } from "@hooks/useIsOfferSummaryPage";
import { useAppConfigSelector } from "@redux/reducers/appConfig";
import { useIsB2BSelected } from "@hooks/useIsB2BSelected";

import {
  useCurrentDealSelector,
  useIsDealPriceLockedSelector,
  useLastCtaSelector,
  useFinanceDataTotalPriceSelector,
  useLOAPriceBreakdownSelector,
  useVACPriceBreakdownSelector,
  useSCFPriceBreakdownSelector,
  useSelectedJourneySelector,
  useDealSelector,
} from "@redux/reducers/deal";
import { usePreselectedDelivery } from "./usePreselectedDelivery";

export const useVehiclePrice = (isPromoCodeApplicable = false) => {
  const router = useRouter();
  const locallySetJourney = useSelectedJourneySelector();
  const financeTotalPrice = useFinanceDataTotalPriceSelector();
  const isPriceLocked = useIsDealPriceLockedSelector();
  const loaPriceBreakdown = useLOAPriceBreakdownSelector();
  const vacPriceBreakdown = useVACPriceBreakdownSelector();
  const scfPriceBreakdown = useSCFPriceBreakdownSelector();
  const currentDeal = useCurrentDealSelector();
  const lastCta = useLastCtaSelector();
  const formatDeal = useFormattedDeal();
  const isOfferSummaryPage = useIsOfferSummaryPage();
  const reduxDeal = useDealSelector();
  const appConfig = useAppConfigSelector();
  const isDeliveryPreselected = !!usePreselectedDelivery();
  const isB2BSelected = useIsB2BSelected();

  if (isOfferSummaryPage && isPriceLocked) {
    const formattedDeal = formatDeal(currentDeal);
    const journey = formattedDeal.financeSimulation.journey;

    const {
      loaPriceBreakdown,
      lldPriceBreakdown,
      scfPriceBreakdown,
    } = formattedDeal;

    switch (journey) {
      case JOURNEY_TYPE.CASH: {
        const isRegistrationDiscountApplied = getIsRegistrationDiscountApplied(
          router.pathname,
          formattedDeal.lastCta
        );

        const isDeliveryIncluded = getIsDeliveryPriceIncluded(
          router.pathname,
          formattedDeal.lastCta,
          isDeliveryPreselected
        );

        const { netPrice } = priceAlgorithm.getTotalPrice(
          formattedDeal,
          {
            car: { netPrice: currentDeal.extendedVehicleInfo.netPrice },
            configuration: currentDeal.configuration,
          },
          {
            isRegistrationIncluded: isRegistrationDiscountApplied,
            isDeliveryIncluded,
            isPromoCodeApplicable,
          }
        );

        return netPrice;
      }

      case JOURNEY_TYPE.LOA:
        return isB2BSelected
          ? loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.regularPayment.amountExclTax
          : loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.regularPayment.amountInclTax;

      case JOURNEY_TYPE.LLD:
        return lldPriceBreakdown?.vehicleSimulation?.result.teaser.data
          .regularPayment.amountInclTax;

      case JOURNEY_TYPE.VAC:
        return vacPriceBreakdown?.vehicleSimulation?.result.teaser.data
          .regularPayment.amountInclTax;

      case JOURNEY_TYPE.SCF:
        return scfPriceBreakdown?.vehicleSimulation?.result.teaser.data
          .regularPayment.amountInclTax;

      default:
        return null;
    }
  }

  switch (locallySetJourney) {
    case JOURNEY_TYPE.CASH: {
      const isRegistrationDiscountApplied = getIsRegistrationDiscountApplied(
        router.pathname,
        lastCta
      );

      const isDeliveryIncluded = getIsDeliveryPriceIncluded(
        router.pathname,
        lastCta,
        isDeliveryPreselected
      );

      const { netPrice } = priceAlgorithm.getTotalPrice(reduxDeal, appConfig, {
        isRegistrationIncluded: isRegistrationDiscountApplied,
        isDeliveryIncluded,
        isPromoCodeApplicable,
      });

      return netPrice;
    }

    case JOURNEY_TYPE.LOA:
      return isB2BSelected
        ? loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.regularPayment.amountExclTax
        : loaPriceBreakdown?.vehicleSimulation?.result.teaser.data.regularPayment.amountInclTax;

    case JOURNEY_TYPE.VAC:
      return vacPriceBreakdown?.vehicleSimulation?.result.teaser.data
        .regularPayment.amountInclTax;

    case JOURNEY_TYPE.LLD:
      return financeTotalPrice;

    case JOURNEY_TYPE.SCF:
      return scfPriceBreakdown?.vehicleSimulation?.result.teaser.data
        .regularPayment.amountInclTax;

    default:
      return null;
  }
};
