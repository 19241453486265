import { useTranslation } from "@lib/i18n";

import { REQUEST_ERROR_CODES } from "@shared/constants";

export const useGetErrorMessage = () => {
  const { t } = useTranslation();

  return errorCode => {
    switch (errorCode) {
      case REQUEST_ERROR_CODES.FINANCE_WIDGET_RESET:
        return t("errorMessages.financeWidgetResetError");

      case REQUEST_ERROR_CODES.PRECONFIGURATION_UNAVAILABLE:
        return t("basket.errorMessages.preconfigurationUnavailable");

      case REQUEST_ERROR_CODES.OPTIONS_UNAVAILABLE:
        return t("basket.errorMessages.optionsUnavailable");

      case REQUEST_ERROR_CODES.CASH_PRICE_CHANGED:
        return t("basket.errorMessages.priceChanged");

      case REQUEST_ERROR_CODES.FINANCE_PRICE_CHANGED:
        return t("basket.errorMessages.priceChanged");

      case REQUEST_ERROR_CODES.LEAD_TIME_CHANGED:
        return t("basket.errorMessages.leadTimeChanged");

      case REQUEST_ERROR_CODES.DELIVERY_METHOD_UNAVAILABLE:
        return t("basket.errorMessages.deliveryMethodIsUnavailable");

      case REQUEST_ERROR_CODES.DELIVERY_POINT_UNAVAILABLE:
        return t("basket.errorMessages.deliveryPointIsUnavailable");

      default:
        throw new Error(`Unknown error code ${errorCode}`);
    }
  };
};
