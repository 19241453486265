import React, { useRef } from "react";
import styled from "styled-components";
import { bool, func } from "prop-types";
import { Trans, useTranslation } from "@lib/i18n";
import { useDispatch } from "react-redux";

import Typography from "@components/shared/Typography";
import Modal from "@components/shared/Modal";
import Button from "@components/shared/Button";

import { fontSize, fontWeight, color, prop } from "@components/shared/utils";
import { usePrimaryColor } from "@hooks/usePrimaryColor";
import { toggleIsScrappageSelectedAction } from "@redux/actions/dealActions";
import { scrappageSelectChangeEvent } from "@gtm/events/homepage";
import gtm from "@gtm/core";

const StyledModal = styled(Modal)`
  padding: 1rem 2rem;
`;

const StyledHeadingTypography = styled(Typography)`
  color: ${prop("color")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("lg")};
  text-align: center;
  text-transform: uppercase;
`;

const StyledTypography = styled(Typography)`
  color: ${prop("color")};
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
  text-align: center;
  text-transform: uppercase;
`;

const StyledShareButton = styled(Button)`
  display: block;
  font-weight: ${fontWeight("bold")};
  font-size: ${fontSize("md")};
  padding: 1rem;
  margin: 1rem;
  border: 3px solid ${prop("color")};
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
`;

const ButtonsInnerWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

const StyledAnchor = styled.a`
  color: ${color("amiOrange")};
  text-decoration: underline;
`;

const ScrappageDetailsModal = ({
  isOpen,
  isScrappageSelected,
  onClose,
  closeScrappageModal,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const primaryColor = usePrimaryColor();

  const modalLastElement = useRef(null);

  const scrappageBonusLink = t("price.scrappageBonusLink");

  const toggleScrappageCloseModal = label => () => {
    gtm.fire(
      scrappageSelectChangeEvent({
        isScrappage: isScrappageSelected ? "no" : "yes",
        label: label,
      })
    );
    dispatch(toggleIsScrappageSelectedAction());

    onClose();
  };

  const closeModalAndFireGTM = label => () => {
    gtm.fire(
      scrappageSelectChangeEvent({
        isScrappage: isScrappageSelected ? "yes" : "no",
        label: label,
      })
    );

    onClose();
  };

  return (
    <StyledModal
      isOpen={isOpen}
      withCloseIcon
      lastElement={modalLastElement}
      ariaLabel={t("price.modalScrappage.heading")}
      onClose={closeScrappageModal}
    >
      <StyledHeadingTypography color={primaryColor}>
        {t("price.modalScrappage.heading")}
      </StyledHeadingTypography>
      <Typography size="sm" margin="0.5rem 0">
        <Trans i18nKey="price.modalScrappage.mainInfo">
          <StyledAnchor
            href={scrappageBonusLink}
            target="_blank"
            rel="noopener noreferrer"
          />
        </Trans>
      </Typography>
      <StyledTypography color={primaryColor}>
        {t("price.modalScrappage.bonusInfo")}
      </StyledTypography>
      <ButtonsWrap>
        <ButtonsInnerWrap>
          <StyledShareButton
            color={primaryColor}
            styled={{ borderColor: primaryColor }}
            onClick={
              isScrappageSelected
                ? toggleScrappageCloseModal(t("price.modalScrappage.buttonNo"))
                : closeModalAndFireGTM(t("price.modalScrappage.buttonNo"))
            }
          >
            {t("price.modalScrappage.buttonNo")}
          </StyledShareButton>
          <StyledShareButton
            ref={modalLastElement}
            inverted
            color={primaryColor}
            onClick={
              isScrappageSelected
                ? closeModalAndFireGTM(t("price.modalScrappage.buttonYes"))
                : toggleScrappageCloseModal(t("price.modalScrappage.buttonYes"))
            }
          >
            {t("price.modalScrappage.buttonYes")}
          </StyledShareButton>
        </ButtonsInnerWrap>
      </ButtonsWrap>
    </StyledModal>
  );
};

ScrappageDetailsModal.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  closeScrappageModal: func.isRequired,
  isScrappageSelected: bool,
};

export default ScrappageDetailsModal;
