import { useState } from "react";

function generateId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const useRandomKey = () => {
  const [randomKey, setRandomKey] = useState(generateId(10));

  const generateRandomKey = () => {
    setRandomKey(generateId(10));
  };

  return [randomKey, generateRandomKey];
};
