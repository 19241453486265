import { useGetJourneySpecificConfig } from "@hooks/useGetJourneySpecificConfig";
import { useBusinessModelSelector } from "@redux/reducers/deal";
import { useDeliveryMethods } from "./useDeliveryMethods";

export const usePreselectedDelivery = () => {
  const { preselectedDelivery } = useGetJourneySpecificConfig();
  const businessModel = useBusinessModelSelector();
  const deliveryMethods = useDeliveryMethods(businessModel);
  const enabledDeliveryMethods = deliveryMethods.filter(
    ({ enabled }) => enabled
  );

  // In case there's a wrong value for a preselected delivery, return null
  if (
    !enabledDeliveryMethods.find(method => method.id === preselectedDelivery)
  ) {
    return null;
  }

  return preselectedDelivery;
};
