class DeliveryMethodValidator {
  validate(allDeliveryMethods, selectedDeliveryMethod) {
    const result = {
      isDeliveryMethodFound: null,
      isDeliveryMethodEnabled: null,
      isDeliveryPointAvailable: null,
    };

    // If no delivery method is selected, don't raise an error
    // Keep in mind that only === false values are considered errors in the basket
    if (!selectedDeliveryMethod || !selectedDeliveryMethod.id) {
      return result;
    }

    const matchingDeliveryMethod = allDeliveryMethods.find(
      ({ id }) => id === selectedDeliveryMethod.id
    );

    result.isDeliveryMethodFound = !!matchingDeliveryMethod;

    if (matchingDeliveryMethod) {
      result.isDeliveryMethodEnabled = matchingDeliveryMethod.enabled;
      // This is just a dummy condition, but if we ever need to check delivery point availability,
      // we can just update this condition..
      if (
        result.isDeliveryMethodEnabled &&
        matchingDeliveryMethod.type === "delivery-points"
      ) {
        result.isDeliveryPointAvailable = true;
      }
    }

    return result;
  }
}

module.exports = DeliveryMethodValidator;
