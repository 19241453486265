import { useVehiclePrice } from "@hooks/useVehiclePrice";

import { useIsLLDJourneySelected } from "@hooks/useIsLLDJourneySelected";
import { useIsLOAJourneySelected } from "@hooks/useIsLOAJourneySelected";
import { useIsVACJourneySelected } from "@hooks/useIsVACJourneySelected";
import { useIsSCFJourneySelected } from "@hooks/useIsSCFJourneySelected";
import { useIsEcoBonusEligible } from "@hooks/useIsEcoBonusEligible";
import { useIsLeasingJourneySelected } from "./useIsLeasingJourneySelected";

import {
  useLLDPriceBreakdownRequestStatusSelector,
  useLOAPriceBreakdownRequestStatusSelector,
  useVACPriceBreakdownRequestStatusSelector,
  useSCFPriceBreakdownRequestStatusSelector,
} from "@redux/reducers/requests";

import {
  useLOAPriceBreakdownSelector,
  useVACPriceBreakdownSelector,
  useVACDataDepositSelector,
  useSCFPriceBreakdownSelector,
  useFinanceDataFirstRentalSelector,
} from "@redux/reducers/deal";

export const useGetPriceDetailProps = (isPromoCodeApplicable = false) => {
  const isLLDJourneySelected = useIsLLDJourneySelected();
  const isLOAJourneySelected = useIsLOAJourneySelected();
  const isVACJourneySelected = useIsVACJourneySelected();
  const isSCFJourneySelected = useIsSCFJourneySelected();
  const isLeasingJourneySelected = useIsLeasingJourneySelected();

  const isEcoBonusEligible = useIsEcoBonusEligible();
  const firstRental = useFinanceDataFirstRentalSelector();
  const vacDeposit = useVACDataDepositSelector();
  const loaPriceBreakdown = useLOAPriceBreakdownSelector();
  const vacPriceBreakdown = useVACPriceBreakdownSelector();
  const scfPriceBreakdown = useSCFPriceBreakdownSelector();
  const vehiclePrice = useVehiclePrice(isPromoCodeApplicable);

  // TODO SCF: check if it has deposit that needs to be added here
  const getDeposit = () => {
    if (isVACJourneySelected) {
      return vacDeposit;
    }

    if (isLLDJourneySelected) {
      return firstRental;
    }

    return null;
  };

  const loaTeaser = loaPriceBreakdown?.vehicleSimulation?.result.teaser;
  const vacTeaser = vacPriceBreakdown?.vehicleSimulation?.result.teaser;
  const scfTeaser = scfPriceBreakdown?.vehicleSimulation?.result.teaser;

  const teaser = loaTeaser || vacTeaser || scfTeaser;

  const {
    isPending: isLOAPriceBreakdownRequestPending,
    isError: isLOAPriceBreakdownRequestError,
  } = useLOAPriceBreakdownRequestStatusSelector();

  const {
    isPending: isLLDPriceBreakdownRequestPending,
    isError: isLLDPriceBreakdownRequestError,
  } = useLLDPriceBreakdownRequestStatusSelector();

  const {
    isPending: isVACPriceBreakdownRequestPending,
    isError: isVACPriceBreakdownRequestError,
  } = useVACPriceBreakdownRequestStatusSelector();

  const {
    isPending: isSCFPriceBreakdownRequestPending,
    isError: isSCFPriceBreakdownRequestError,
  } = useSCFPriceBreakdownRequestStatusSelector();

  const isLeasingRequestLoading =
    (isLLDJourneySelected && isLLDPriceBreakdownRequestPending) ||
    (isLOAJourneySelected && isLOAPriceBreakdownRequestPending) ||
    (isVACJourneySelected && isVACPriceBreakdownRequestPending) ||
    (isSCFJourneySelected && isSCFPriceBreakdownRequestPending);

  const isFinanceRequestError =
    isLLDPriceBreakdownRequestError ||
    isLOAPriceBreakdownRequestError ||
    isVACPriceBreakdownRequestError ||
    isSCFPriceBreakdownRequestError;

  // No finance widget available from Santander
  const withFinanceWidgetButton =
    isLeasingJourneySelected && !isSCFJourneySelected;

  const withEcoBonus = isEcoBonusEligible;

  const isPersonalisedVACOffer =
    isVACJourneySelected &&
    vacPriceBreakdown?.vehicleSimulation?.result?.globalText?.detailTitle.includes(
      "Personalised"
    );

  const deposit = getDeposit();
  return {
    amount: vehiclePrice,
    isLeasingRequestLoading,
    isPersonalisedVACOffer,
    isFinanceRequestError,
    withFinanceWidgetButton,
    withEcoBonus,
    deposit,
    teaser,
  };
};
